<template>
  <div class="progress-bar">
    <div
      class="save-button"
      @click="$emit('save')"
      v-if="enableSave && progress < 100"
    >
      <span>
        {{ $t('components.processProgressBar.save') }}
      </span>
      <span class="mdi mdi-content-save-outline" />
    </div>
    <div class="progress-bar__inner">
      <ProgressBar :progress="progress" />
      <span
        class="mdi mdi-checkbox-marked-circle-outline"
        v-if="progress === 100"
      />
    </div>
  </div>
</template>
<script>
import { ProgressBar } from 'ff-components-lib';

export default {
  name: 'ProcessProgressBar',
  components: {
    ProgressBar,
  },
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    enableSave: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.progress-bar {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 84%;
  gap: 14px;

  &__inner{
    display: flex;
    align-items: center;
    gap: 10px;
    height: 24px;
    margin-top: 38px;

    .mdi {
      color: $primary;
      font-size: 24px;
      height: 24px;
      line-height: 1;
    }
  }

  .save-button {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 16px;
    font-weight: 600;
    color: $primary;
    cursor: pointer;

    .mdi {
      font-size: 24px;
      line-height: 24px;
      height: 24px;
      width: 24px;
    }

    & + .progress-bar__inner {
      margin-top: 0px;
    }
  }
}
</style>
