var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProcessCard',{attrs:{"title":_vm.$t('sections.rent-process.rentDetailsSection.title')}},[_c('template',{slot:"body"},[_c('div',{staticClass:"inputs"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-3 col-sm-4"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t('sections.rent-process.rentDetailsSection.inputs.rent.value'),"placeholder":_vm.$t(
                'sections.rent-process.rentDetailsSection.inputs.rent.placeholder'
              ),"unit":_vm.$t(
                'sections.rent-process.rentDetailsSection.inputs.rent.unit'
              ),"value":_vm.value,"valid":this.validFields.value,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('value', e); }}})],1)]),_c('VerticalTitleInput',{attrs:{"title":_vm.$t('sections.rent-process.rentDetailsSection.inputs.title.value'),"placeholder":_vm.$t(
            'sections.rent-process.rentDetailsSection.inputs.title.placeholder'
          ),"value":_vm.title,"valid":this.validFields.title,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('title', e); }}}),_c('VerticalTitleInput',{attrs:{"type":"textarea","title":_vm.$t(
            'sections.rent-process.rentDetailsSection.inputs.description.value'
          ),"placeholder":_vm.$t(
            'sections.rent-process.rentDetailsSection.inputs.description.placeholder'
          ),"value":_vm.description,"valid":this.validFields.description,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('description', e); }}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }