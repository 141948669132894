var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProcessCard',{attrs:{"title":_vm.$t('sections.rent-process.contactInfoSection.title')}},[_c('template',{slot:"body"},[_c('div',{staticClass:"inputs"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
            'sections.rent-process.contactInfoSection.inputs.name.value'
          ),"placeholder":_vm.$t(
            'sections.rent-process.contactInfoSection.inputs.name.placeholder'
          ),"value":_vm.ownerName,"valid":this.validFields.ownerName,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('ownerName', e); }}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-7 col-12 mb-sm-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
                'sections.rent-process.contactInfoSection.inputs.email.value'
              ),"placeholder":_vm.$t(
                'sections.rent-process.contactInfoSection.inputs.email.placeholder'
              ),"disabled":"","value":_vm.ownerEmail,"valid":this.validFields.ownerEmail,"error-label":_vm.$t('components.input.invalidEmail')},on:{"input":function (e) { return _vm.handleInput('ownerEmail', e); }}})],1),_c('div',{staticClass:"col-sm-5 col-12 mb-sm-0 mb-6"},[_c('PhoneInput',{attrs:{"name":"phone-number","title":_vm.$t(
                'sections.rent-process.contactInfoSection.inputs.phone.value'
              ),"placeholder":_vm.$t(
                'sections.rent-process.contactInfoSection.inputs.phone.placeholder'
              ),"code-placeholder":_vm.$t(
                'sections.rent-process.contactInfoSection.inputs.countryCode.placeholder'
              ),"valid":this.validFields.ownerPhone,"valid-code":this.validFields.ownerPhoneCountryCode,"error-label":_vm.$t('components.input.invalidPhone'),"value":_vm.ownerPhone,"code-value":_vm.ownerPhoneCountryCode,"items":_vm.$t('arrays.phoneCountryCodes')},on:{"input":function (e) { return _vm.handleInput('ownerPhone', e); },"codeInput":function (e) { return _vm.handleInput('ownerPhoneCountryCode', e); }}})],1)])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }