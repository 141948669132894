<template>
  <div class="buttons-footer">
    <BaseButton
      large
      :label="$t('components.processFooter.finishButton')"
      v-if="finish"
      @click="$emit('finish')"
    />
    <div
      class="buttons-footer--inner"
      v-else-if="!finish && getBreakpoints.smAndUp"
    >
      <div
        class="return-button"
        @click="$emit('back')"
      >
        <span class="mdi mdi-chevron-left" />
        <span>
          {{ $t("components.processFooter.backButton") }}
        </span>
      </div>
      <div
        class="skip-button"
        @click="$emit('skip')"
        v-if="showSkip"
      >
        <span class="skip">
          {{ $t("components.processFooter.skip") }}
        </span>
        <span class="description">
          {{ $t("components.processFooter.skipDescription") }}
        </span>
      </div>
      <BaseButton
        large
        :label="$t('components.processFooter.nextButton')"
        :disabled="nextDisabled"
        class="next-button"
        @click="$emit('next')"
      >
        <template slot>
          <span class="mdi mdi-chevron-right" />
        </template>
      </BaseButton>
    </div>
    <div
      class="buttons-footer--inner-mobile"
      v-else
    >
      <div
        class="skip-button"
        @click="$emit('skip')"
        v-if="showSkip"
      >
        <span class="skip">
          {{ $t("components.processFooter.skip") }}
        </span>
        <span class="description">
          {{ $t("components.processFooter.skipDescription") }}
        </span>
      </div>
      <div class="d-flex justify-content-between">
        <div
          class="return-button"
          @click="$emit('back')"
        >
          <span class="mdi mdi-chevron-left" />
          <span>
            {{ $t("components.processFooter.backButton") }}
          </span>
        </div>
        <BaseButton
          large
          :label="$t('components.processFooter.nextButton')"
          :disabled="nextDisabled"
          class="next-button"
          @click="$emit('next')"
        >
          <template slot>
            <span class="mdi mdi-chevron-right" />
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BaseButton } from 'ff-components-lib';

export default {
  name: 'ProcessFooter',
  components: {
    BaseButton,
  },
  props: {
    finish: {
      type: Boolean,
      default: false,
    },
    showSkip: {
      type: Boolean,
      default: false,
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
};
</script>
<style lang="scss" scoped>
.buttons-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 84%;

  &--inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: 100%;
  }

  &--inner-mobile {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
    margin-top: 16px;
  }

  .next-button,
  .return-button {
    display: flex;
    align-items: center;
    gap: 8px;

    .mdi {
      font-size: 28px;
      line-height: 28px;
      height: 28px;
      width: 28px;
    }
  }

  .return-button {
    font-weight: 600;
    color: $primary;
    cursor: pointer;
  }

  .skip-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    .skip {
      font-weight: 600;
      color: $primary;
    }

    .description {
      color: $grey;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 770px) {
  .buttons-footer {
    width: 94%;

    .next-button,
    .return-button {
      gap: 6px;

      .mdi {
        font-size: 22px;
        line-height: 22px;
        height: 22px;
        width: 22px;
        aspect-ratio: 1;
      }
    }

    .return-button {
      font-size: 14px;
    }

    .skip-button {
      .skip {
        font-size: 14px;
      }

      .description {
        font-size: 14px;
      }
    }
  }
}
</style>
