<template>
  <ProcessCard :title="$t('sections.rent-process.rentDetailsSection.title')">
    <template slot="body">
      <div class="inputs">
        <div class="row">
          <div class="col-12 col-lg-3 col-sm-4">
            <VerticalTitleInput
              :title="
                $t('sections.rent-process.rentDetailsSection.inputs.rent.value')
              "
              :placeholder="
                $t(
                  'sections.rent-process.rentDetailsSection.inputs.rent.placeholder',
                )
              "
              :unit="
                $t(
                  'sections.rent-process.rentDetailsSection.inputs.rent.unit',
                )
              "
              :value="value"
              :valid="this.validFields.value"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('value', e)"
            />
          </div>
        </div>
        <VerticalTitleInput
          :title="
            $t('sections.rent-process.rentDetailsSection.inputs.title.value')
          "
          :placeholder="
            $t(
              'sections.rent-process.rentDetailsSection.inputs.title.placeholder',
            )
          "
          :value="title"
          :valid="this.validFields.title"
          :error-label="$t('components.input.missingField')"
          @input="(e) => handleInput('title', e)"
        />
        <VerticalTitleInput
          type="textarea"
          :title="
            $t(
              'sections.rent-process.rentDetailsSection.inputs.description.value',
            )
          "
          :placeholder="
            $t(
              'sections.rent-process.rentDetailsSection.inputs.description.placeholder',
            )
          "
          :value="description"
          :valid="this.validFields.description"
          :error-label="$t('components.input.missingField')"
          @input="(e) => handleInput('description', e)"
        />
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import { ProcessCard, VerticalTitleInput } from 'ff-components-lib';

export default {
  name: 'RentProcessRentDetailsSection',
  components: {
    ProcessCard,
    VerticalTitleInput,
  },
  props: {
    fields: {
      type: Object,
      default: null,
    },
    validFields: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      value: undefined,
      title: undefined,
      description: undefined,
    };
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      this.$emit('input', { field, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .horizontal_input {
    display: flex;
    align-items: center;
    gap: 16px;

    font-weight: 500;
    line-height: 24px;
  }
}
</style>
