<template>
  <ProcessCard :title="$t('sections.rent-process.propertyInfoSection.title')">
    <template slot="body">
      <div class="inputs">
        <VerticalTitleInput
          :title="
            $t('sections.rent-process.propertyInfoSection.inputs.address.value')
          "
          :placeholder="
            $t(
              'sections.rent-process.propertyInfoSection.inputs.address.placeholder',
            )
          "
          :value="address"
          :valid="this.validFields.address"
          :error-label="$t('components.input.missingField')"
          @input="(e) => handleInput('address', e)"
        />
        <div class="row">
          <div class="col-md-6 col-12 mb-md-0 mb-6">
            <VerticalTitleInput
              :title="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.city.value',
                )
              "
              :placeholder="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.city.placeholder',
                )
              "
              :value="city"
              :valid="this.validFields.city"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('city', e)"
            />
          </div>
          <div class="col-sm-5 col-md-3 col-12 mb-sm-0 mb-6">
            <VerticalTitleInput
              :title="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.postalCode.value',
                )
              "
              :placeholder="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.postalCode.placeholder',
                )
              "
              :value="postalCode"
              :valid="this.validFields.postalCode"
              :error-label="$t('components.input.invalidNumber')"
              @input="(e) => handleInput('postalCode', e)"
            />
          </div>
          <div class="col-sm-5 col-md-3 col-12">
            <VerticalTitleInput
              type="dropdown"
              :title="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.district.value',
                )
              "
              :placeholder="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.district.placeholder',
                )
              "
              :value="district"
              :items="$t('arrays.districts')"
              :valid="this.validFields.district"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('district', e)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-12 mb-sm-0 mb-6">
            <VerticalTitleInput
              name="typology"
              :title="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.typology.value',
                )
              "
              :placeholder="$t('components.dropdown.defaultPlaceholder')"
              :value="typology"
              type="dropdown"
              :items="$t('arrays.typology')"
              :valid="this.validFields.typology"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('typology', e)"
            />
          </div>
          <div class="col-sm-4 col-12 mb-sm-0 mb-6">
            <VerticalTitleInput
              :title="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.area.value',
                )
              "
              :placeholder="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.area.placeholder',
                )
              "
              :unit="
                $t('sections.rent-process.propertyInfoSection.inputs.area.unit')
              "
              :value="area"
              :valid="this.validFields.area"
              :error-label="$t('components.input.invalidNumber')"
              @input="(e) => handleInput('area', e)"
            />
          </div>
          <div class="col-sm-4 col-12">
            <VerticalTitleInput
              :title="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.floor.value',
                )
              "
              :placeholder="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.floor.placeholder',
                )
              "
              :value="floor"
              :valid="this.validFields.floor"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('floor', e)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 col-12 mb-sm-0 mb-6">
            <VerticalTitleInput
              :title="
                $t('sections.rent-process.propertyInfoSection.inputs.wcs.value')
              "
              :placeholder="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.wcs.placeholder',
                )
              "
              :value="wcs"
              :valid="this.validFields.wcs"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('wcs', e)"
            />
          </div>
          <div class="col-sm-3 col-12 mb-sm-0 mb-6">
            <VerticalTitleInput
              :title="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.garageSpaces.value',
                )
              "
              :placeholder="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.garageSpaces.placeholder',
                )
              "
              :value="garageSpaces"
              :valid="this.validFields.garageSpaces"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('garageSpaces', e)"
            />
          </div>
          <!-- <div class="col-sm-3 col-12 mb-sm-0 mb-6">
            <VerticalTitleInput
              :title="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.elevators.value',
                )
              "
              :placeholder="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.elevators.placeholder',
                )
              "
              :value="elevators"
              :valid="this.validFields.elevators"
              :error-label="$t('components.input.invalidNumber')"
              @input="(e) => handleInput('elevators', e)"
            />
          </div> -->
          <div class="col-sm-3 col-12">
            <VerticalTitleInput
              name="energeticClass"
              :title="
                $t(
                  'sections.rent-process.propertyInfoSection.inputs.energeticClass.value',
                )
              "
              :placeholder="$t('components.dropdown.defaultPlaceholder')"
              :value="energeticClass"
              type="dropdown"
              :items="$t('arrays.energeticClass')"
              :valid="this.validFields.energeticClass"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('energeticClass', e)"
            />
          </div>
        </div>
        <div class="characteristics-group">
          <span>
            {{
              $t('sections.rent-process.propertyInfoSection.characteristics')
            }}
          </span>
          <CheckboxGroup
            :breakpoint="
              this.getBreakpoints.mdAndUp
                ? 'md'
                : this.getBreakpoints.sm
                  ? 'sm'
                  : 'xs'
            "
            :options="
              $t(
                'sections.rent-process.propertyInfoSection.characteristicsOptions',
              )
            "
            :values="characteristics"
            @input="(e) => handleInput('characteristics', e)"
          />
        </div>
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  ProcessCard,
  VerticalTitleInput,
  CheckboxGroup,
} from 'ff-components-lib';

export default {
  name: 'RentProcessPropertyInfoSection',
  components: {
    ProcessCard,
    VerticalTitleInput,
    CheckboxGroup,
  },
  props: {
    fields: {
      type: Object,
      default: null,
    },
    validFields: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      address: undefined,
      city: undefined,
      postalCode: undefined,
      district: undefined,
      typology: undefined,
      area: undefined,
      floor: undefined,
      energeticClass: undefined,
      wcs: undefined,
      garageSpaces: undefined,
      // elevators: undefined,
      characteristics: [],
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      this.$emit('input', { field, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .characteristics-group {
    display: flex;
    flex-direction: column;
    gap: 16px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
