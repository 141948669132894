import { render, staticRenderFns } from "./ProcessFooter.vue?vue&type=template&id=f6f5751c&scoped=true&"
import script from "./ProcessFooter.vue?vue&type=script&lang=js&"
export * from "./ProcessFooter.vue?vue&type=script&lang=js&"
import style0 from "./ProcessFooter.vue?vue&type=style&index=0&id=f6f5751c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6f5751c",
  null
  
)

export default component.exports