<template>
  <ProcessLayout
    :steps="5"
    :finish="processStep === 5"
    :show-skip="showSkip"
    :next-disabled="!validStep"
    @back="previousStep"
    @next="nextStep"
    @skip="nextStep"
    @finish="handleFinish"
  >
    <template slot="body">
      <RentProcessPropertyInfoSection
        v-if="processStep === 0"
        :fields="fields"
        :valid-fields="validFields"
        @input="handleInput"
      />
      <RentProcessRentDetailsSection
        v-else-if="processStep === 1"
        :fields="fields"
        :valid-fields="validFields"
        @input="handleInput"
      />
      <RentProcessContactInfoSection
        v-else-if="processStep === 2"
        :fields="fields"
        :valid-fields="validFields"
        @input="handleInput"
      />
      <RentProcessPhotosSection
        v-else-if="processStep === 3"
        :fields="fields"
        :valid-fields="validFields"
        @input="handleInput"
      />
      <RentProcessDocumentsSection
        v-else-if="processStep === 4"
        :fields="fields"
        @input="handleInput"
      />
      <RentProcessEndSection v-else-if="processStep === 5" />
    </template>
  </ProcessLayout>
</template>
<script>
import { cloneDeep, merge } from 'lodash';
import { mapGetters } from 'vuex';
import {
  validateZipCode,
  validateEmail,
  validatePhone,
  validateNumber,
} from '@/utils';
import ProcessLayout from '@/components/layout/process/ProcessLayout';
import RentProcessPropertyInfoSection from '@/components/sections/processes/rent-process/RentProcessPropertyInfoSection';
import RentProcessRentDetailsSection from '@/components/sections/processes/rent-process/RentProcessRentDetailsSection';
import RentProcessContactInfoSection from '@/components/sections/processes/rent-process/RentProcessContactInfoSection';
import RentProcessPhotosSection from '@/components/sections/processes/rent-process/RentProcessPhotosSection';
import RentProcessDocumentsSection from '@/components/sections/processes/rent-process/RentProcessDocumentsSection';
import RentProcessEndSection from '@/components/sections/processes/rent-process/RentProcessEndSection';

export default {
  name: 'RentProcessScreen',
  components: {
    ProcessLayout,
    RentProcessPropertyInfoSection,
    RentProcessRentDetailsSection,
    RentProcessContactInfoSection,
    RentProcessPhotosSection,
    RentProcessDocumentsSection,
    RentProcessEndSection,
  },
  data() {
    return {
      processStep: 0,
      fields: {
        address: undefined,
        city: undefined,
        postalCode: undefined,
        district: undefined,
        typology: undefined,
        area: undefined,
        floor: undefined,
        energeticClass: undefined,
        wcs: undefined,
        garageSpaces: undefined,
        // elevators: undefined,
        characteristics: [],
        value: undefined,
        title: undefined,
        description: undefined,
        ownerName: undefined,
        ownerEmail: undefined,
        ownerPhoneCountryCode: undefined,
        ownerPhone: undefined,
        photos: [],
        buildingPassbook: undefined,
        cc: undefined,
        energeticCertificate: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['getUser']),
    validAddress() {
      return (
        this.fields.address === undefined
        || (typeof this.fields.address === 'string'
          && this.fields.address.length > 0)
      );
    },
    validCity() {
      return (
        this.fields.city === undefined
        || (typeof this.fields.city === 'string' && this.fields.city.length > 0)
      );
    },
    validPostalCode() {
      if (this.fields.postalCode === undefined) {
        return true;
      }
      if (this.fields.postalCode && this.fields.postalCode.length > 0) {
        return validateZipCode(this.fields.postalCode);
      }
      return false;
    },
    validDistrict() {
      return (
        this.fields.district === undefined
        || (typeof this.fields.district === 'string'
          && this.fields.district.length > 0)
      );
    },
    validTypology() {
      return (
        this.fields.typology === undefined
        || (typeof this.fields.typology === 'string'
          && this.fields.typology.length > 0)
      );
    },
    validArea() {
      if (this.fields.area === undefined) {
        return true;
      }
      if (this.fields.area && this.fields.area.length > 0) {
        return validateNumber(this.fields.area);
      }
      return false;
    },
    validFloor() {
      return (
        this.fields.floor === undefined
        || (typeof this.fields.floor === 'string' && this.fields.floor.length > 0)
      );
    },
    validWCs() {
      if (this.fields.wcs === undefined) {
        return true;
      }
      if (this.fields.wcs && this.fields.wcs.length > 0) {
        return validateNumber(this.fields.wcs);
      }
      return false;
    },
    validGarageSpaces() {
      if (this.fields.garageSpaces === undefined) {
        return true;
      }
      if (this.fields.garageSpaces && this.fields.garageSpaces.length > 0) {
        return validateNumber(this.fields.garageSpaces);
      }
      return false;
    },
    /* validElevators() {
      if (this.fields.elevators === undefined) {
        return true;
      }
      if (this.fields.elevators && this.fields.elevators.length > 0) {
        return validateNumber(this.fields.elevators);
      }
      return false;
    }, */
    validCharacteristics() {
      return (
        Array.isArray(this.fields.characteristics)
        && this.fields.characteristics.every((c) => typeof c === 'string')
      );
    },
    validValue() {
      if (this.fields.value === undefined) {
        return true;
      }
      if (this.fields.value && this.fields.value.length > 0) {
        return validateNumber(this.fields.value);
      }
      return false;
    },
    validTitle() {
      return (
        this.fields.title === undefined
        || (typeof this.fields.title === 'string' && this.fields.title.length > 0)
      );
    },
    validDescription() {
      return (
        this.fields.description === undefined
        || (typeof this.fields.description === 'string'
          && this.fields.description.length > 0)
      );
    },
    validOwnerName() {
      return (
        this.fields.ownerName === undefined
        || (typeof this.fields.ownerName === 'string'
          && this.fields.ownerName.length > 0)
      );
    },
    validOwnerEmail() {
      if (this.fields.ownerEmail === undefined) {
        return true;
      }
      if (this.fields.ownerEmail && this.fields.ownerEmail.length > 0) {
        return validateEmail(this.fields.ownerEmail);
      }
      return false;
    },
    validOwnerPhoneCountryCode() {
      return (
        this.fields.ownerPhoneCountryCode === undefined
        || (typeof this.fields.ownerPhoneCountryCode === 'string'
          && this.fields.ownerPhoneCountryCode.length > 0)
      );
    },
    validOwnerPhone() {
      if (this.fields.ownerPhone === undefined) {
        return true;
      }
      if (this.fields.ownerPhone && this.fields.ownerPhone.length > 0) {
        return validatePhone(this.fields.ownerPhone);
      }
      return false;
    },
    validFields() {
      return {
        address: this.validAddress,
        city: this.validCity,
        postalCode: this.validPostalCode,
        district: this.validDistrict,
        typology: this.validTypology,
        area: this.validArea,
        floor: this.validFloor,
        wcs: this.validWCs,
        garageSpaces: this.validGarageSpaces,
        // elevators: this.validElevators,
        characteristics: this.validCharacteristics,
        value: this.validValue,
        title: this.validTitle,
        description: this.validDescription,
        ownerName: this.validOwnerName,
        ownerEmail: this.validOwnerEmail,
        ownerPhoneCountryCode: this.validOwnerPhoneCountryCode,
        ownerPhone: this.validOwnerPhone,
      };
    },
    validStep() {
      if (this.processStep === 0) {
        return (
          this.fields.address != null
          && this.validAddress
          && this.fields.city != null
          && this.validCity
          && this.fields.postalCode != null
          && this.validPostalCode
          && this.fields.district != null
          && this.validDistrict
          && this.fields.typology != null
          && this.validTypology
          && this.fields.area != null
          && this.validArea
          && this.fields.floor != null
          && this.validFloor
          && this.fields.wcs != null
          && this.validWCs
          && this.fields.garageSpaces != null
          && this.validGarageSpaces
          /* && this.fields.elevators != null
          && this.validElevators */
          && this.fields.characteristics != null
          && this.validCharacteristics
        );
      }
      if (this.processStep === 1) {
        return (
          this.fields.value != null
          && this.validValue
          && this.fields.title != null
          && this.validTitle
          && this.fields.description != null
          && this.validDescription
        );
      }
      if (this.processStep === 2) {
        return (
          this.fields.ownerName != null
          && this.validOwnerName
          && this.fields.ownerEmail != null
          && this.validOwnerEmail
          && this.fields.ownerPhoneCountryCode != null
          && this.validOwnerPhoneCountryCode
          && this.fields.ownerPhone != null
          && this.validOwnerPhone
        );
      }
      if (this.processStep === 3) {
        return this.fields.photos != null && this.fields.photos.length > 0;
      }
      if (this.processStep === 4) {
        return (
          this.fields.buildingPassbook != null
          || this.fields.cc != null
          || this.fields.energeticCertificate != null
        );
      }
      return true;
    },
    showSkip() {
      return this.processStep === 3 || this.processStep === 4;
    },
  },
  async mounted() {
    this.fields.ownerName = this.getUser.name;
    this.fields.ownerEmail = this.getUser.email;
    this.fields.ownerPhoneCountryCode = this.getUser.phoneCountryCode
      ? this.getUser.phoneCountryCode
      : undefined;
    this.fields.ownerPhone = this.getUser.phone
      ? this.getUser.phone
      : undefined;
    if (this.$route.params.address) {
      this.fields.address = this.$route.params.address;
    }
    if (this.$route.params.id) {
      this.$store.dispatch('storeLoading', true);
      const property = await this.$store.dispatch(
        'getProperty',
        this.$route.params.id,
      );
      property.area = property.area.toString();
      property.wcs = property.wcs.toString();
      property.garageSpaces = property.garageSpaces.toString();
      // property.elevators = property.elevators.toString();
      property.value = property.value.toString();
      this.fields = cloneDeep(merge(this.fields, property));
      this.$store.dispatch('storeLoading', false);

      if (property.buildingPassbook) {
        const buildingPassbookResp = await this.$store.dispatch(
          'getPropertyDocument',
          {
            id: this.$route.params.id,
            fileName: `buildingPassbook/${property.buildingPassbook}`,
            type: 'documents',
          },
        );

        const buildingPassbookBlob = new Blob([buildingPassbookResp.file], {
          type: buildingPassbookResp.fileType,
        });
        this.fields.buildingPassbook = new File(
          [buildingPassbookBlob],
          property.buildingPassbook,
          { type: buildingPassbookResp.fileType },
        );
      }
      if (property.energeticCertificate) {
        const energeticCertificateResp = await this.$store.dispatch(
          'getPropertyDocument',
          {
            id: this.$route.params.id,
            fileName: `energeticCertificate/${property.energeticCertificate}`,
            type: 'documents',
          },
        );

        const energeticCertificateBlob = new Blob(
          [energeticCertificateResp.file],
          {
            type: energeticCertificateResp.fileType,
          },
        );
        this.fields.energeticCertificate = new File(
          [energeticCertificateBlob],
          property.energeticCertificate,
          { type: energeticCertificateResp.fileType },
        );
      }

      const photos = [];
      await Promise.all(
        property.photos.map(async (photo) => {
          const photoResp = await this.$store.dispatch('getPropertyDocument', {
            id: this.$route.params.id,
            fileName: photo,
            type: 'photos',
          });
          const photoBlob = new Blob([photoResp.file], {
            type: photoResp.fileType,
          });
          photos.push(
            new File([photoBlob], photo, { type: photoResp.fileType }),
          );
        }),
      );
      this.fields.photos = photos;
    }
  },
  methods: {
    previousStep() {
      if (this.processStep === 0) {
        this.$router.back();
      }
      if (this.processStep > 0) {
        this.processStep -= 1;
        window.scrollTo(0, 0);
      }
    },
    nextStep() {
      if (this.processStep === 4) {
        this.sendProposal();
      } else if (this.processStep < 5) {
        this.processStep += 1;
        window.scrollTo(0, 0);
      }
    },
    handleInput({ field, value }) {
      this.fields[field] = value;
    },
    async sendProposal() {
      try {
        const proposal = {
          address: this.fields.address,
          city: this.fields.city,
          postalCode: this.fields.postalCode,
          district: this.fields.district,
          typology: this.fields.typology,
          area: Number(this.fields.area),
          floor: this.fields.floor,
          energeticClass: this.fields.energeticClass,
          wcs: Number(this.fields.wcs),
          garageSpaces: Number(this.fields.garageSpaces),
          // elevators: Number(this.fields.elevators),
          characteristics:
            this.fields.characteristics.length > 0
              ? this.fields.characteristics
              : undefined,
          value: Number(this.fields.value),
          title: this.fields.title,
          description: this.fields.description,
          ownerName: this.fields.ownerName,
          ownerEmail: this.fields.ownerEmail,
          ownerPhoneCountryCode: this.fields.ownerPhoneCountryCode,
          ownerPhone: this.fields.ownerPhone,
          listingType: 'RENT',
        };

        const documents = {};
        if (this.fields.buildingPassbook) {
          documents.buildingPassbook = this.fields.buildingPassbook;
        }
        if (this.fields.cc) {
          documents.cc = this.fields.cc;
        }
        if (this.fields.energeticCertificate) {
          documents.energeticCertificate = this.fields.energeticCertificate;
        }

        if (this.$route.params.id) {
          this.$store.dispatch('storeLoading', true);
          await this.$store.dispatch('updateProperty', {
            property: { ...proposal, _id: this.$route.params.id },
            images: this.fields.photos,
            documents,
          });
          this.$store.dispatch('storeLoading', false);
          this.$router.push({
            name: 'dashboard-listing-detail',
            params: {
              id: this.$route.params.id,
            },
          });
        } else {
          this.$store.dispatch('storeLoading', true);

          await this.$store.dispatch('sendRentEmail', proposal);

          await this.$store.dispatch('createProperty', {
            property: proposal,
            images: this.fields.photos,
            documents,
          });
          this.$store.dispatch('storeLoading', false);

          this.processStep += 1;
          window.scrollTo(0, 0);
        }
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
    handleFinish() {
      this.$router.push({ name: 'dashboard-listings' });
    },
  },
};
</script>
<style lang="scss" scoped></style>
