<template>
  <ProcessCard :title="$t('sections.rent-process.documentsSection.title')">
    <template slot="body">
      <div class="inputs">
        <VerticalTitleInput
          type="document"
          :title="$t('sections.rent-process.documentsSection.mainDocuments[0]')"
          :value="buildingPassbook"
          @input="(e) => handleInput('buildingPassbook', e)"
          @delete="handleInput('buildingPassbook', null)"
        />
        <VerticalTitleInput
          type="document"
          :title="$t('sections.rent-process.documentsSection.mainDocuments[1]')"
          :value="energeticCertificate"
          @input="(e) => handleInput('energeticCertificate', e)"
          @delete="handleInput('energeticCertificate', null)"
        />
        <!-- <VerticalTitleInput
          type="document"
          :title="$t('sections.rent-process.documentsSection.mainDocuments[2]')"
          :value="cc"
          @input="(e) => handleInput('cc', e)"
          @delete="handleInput('cc', null)"
        /> -->
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import { ProcessCard, VerticalTitleInput } from 'ff-components-lib';

export default {
  name: 'RentProcessDocumentsSection',
  components: {
    ProcessCard,
    VerticalTitleInput,
  },
  props: {
    fields: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      buildingPassbook: undefined,
      cc: undefined,
      energeticCertificate: undefined,
    };
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      this.$emit('input', { field, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  gap: 34px;
}
</style>
