<template>
  <layout>
    <template slot="body">
      <div class="process-page">
        <div class="gradient-background" />
        <div class="process-content">
          <ProcessProgressBar
            :progress="progress"
            :enable-save="false"
          />
          <slot name="body" />
          <ProcessFooter
            v-if="showFooter"
            :show-skip="showSkip"
            :finish="finish"
            :next-disabled="nextDisabled"
            @back="back"
            @next="next"
            @skip="skip"
            @finish="$emit('finish')"
          />
        </div>
      </div>
    </template>
  </layout>
</template>
<script>
import Layout from '@/components/layout/Layout';
import ProcessProgressBar from '@/components/layout/process/ProcessProgressBar';
import ProcessFooter from '@/components/layout/process/ProcessFooter';

export default {
  name: 'ProcessLayout',
  components: {
    Layout,
    ProcessProgressBar,
    ProcessFooter,
  },
  props: {
    steps: {
      type: Number,
      default: 10,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showSkip: {
      type: Boolean,
      default: false,
    },
    finish: {
      type: Boolean,
      default: false,
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      progress: 0,
    };
  },
  computed: {
    stepIncrement() {
      return 100 / this.steps;
    },
  },
  methods: {
    decrementBar() {
      if (this.progress - this.stepIncrement <= 0) {
        this.progress = 0;
      } else {
        this.progress -= this.stepIncrement;
      }
    },
    incrementBar() {
      if (this.progress + this.stepIncrement >= 100) {
        this.progress = 100;
      } else {
        this.progress += this.stepIncrement;
      }
    },
    back() {
      this.decrementBar();
      this.$emit('back');
    },
    next() {
      this.incrementBar();
      this.$emit('next');
    },
    skip() {
      this.incrementBar();
      this.$emit('skip');
    },
  },
}
</script>
<style lang="scss" scoped>
.process-page {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 90px;
  min-height: 620px;
}

.gradient-background {
  width: 100%;
  height: 90%;
  position: absolute;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba($primary, 0.3) 0%,
    rgba($primary, 0) 100%
  );
}

.process-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 96px 0;
  gap: 48px;
  z-index: 10;
}

@media only screen and (min-width: 2000px) {
  .process-content {
    width: 50%;
  }
}

@media only screen and (max-width: 1580px) {
  .process-page {
    margin-top: 70px;
  }

  .process-content {
    width: 76%;
    margin: 40px 0;
  }
}

@media only screen and (max-width: 900px) {
  .process-content {
    width: 90%;
    gap: 20px;
    margin: 20px 0;
  }
}
</style>
